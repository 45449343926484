/* 230919 do some installation then
https://tailwindcss.com/docs/guides/create-react-app
add below to use tailwind in react
*/
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*231223 need to add this line to use <i> tags with icons after npm install */
@import "../node_modules/bootstrap-icons/font/bootstrap-icons";
